.fade-in {
  animation: 0.7s ease-out 0s 1 ease-out;
}

@keyframes ease-out {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.disabled {
  opacity: 15%;
  cursor: not-allowed;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

footer.nb-footer {
  background-color: #0272f8;
}
footer.nb-footer .about {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 1170px;
  text-align: center;
}
footer.nb-footer .about p {
  font-size: 13px;
  color: white;
  margin-top: 30px;
}
footer.nb-footer .copyright {
  margin-top: 15px;
  background: #111;
  padding: 7px 0;
  color: #999;
  text-align: center;
}
footer.nb-footer .copyright p {
  margin: 0;
  padding: 0;
}


.post-slide{
  background: #fff;
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #bbcbd8;
}
.post-slide .post-img{
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: -12px 15px 8px 15px;
  margin-left: -10px;
}
.post-slide .post-img img{
  width: 100%;
  height: auto;
  transform: scale(1,1);
  transition:transform 0.2s linear;
}
.post-slide:hover .post-img img{
  transform: scale(1.1,1.1);
}
.post-slide .over-layer{
  width:100%;
  height:100%;
  position: absolute;
  top:0;
  left:0;
  opacity:0;
  background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%);
  transition:all 0.50s linear;
}
.post-slide:hover .over-layer{
  opacity:1;
  text-decoration:none;
}
.post-slide .over-layer i{
  position: relative;
  top:45%;
  text-align:center;
  display: block;
  color:#fff;
  font-size:25px;
}
.post-slide .post-content{
  background:#fff;
  padding: 2px 20px 40px;
  border-radius: 15px;
}
.post-slide .post-title a{
  font-size:15px;
  font-weight:bold;
  color:#333;
  display: inline-block;
  text-transform:uppercase;
  transition: all 0.3s ease 0s;
}
.post-slide .post-title a:hover{
  text-decoration: none;
  color:#3498db;
}
.post-slide .post-description{
  line-height:24px;
  color:#808080;
  margin-bottom:25px;
}
.post-slide .post-date{
  color:#a9a9a9;
  font-size: 14px;
}
.post-slide .post-date i{
  font-size:20px;
  margin-right:8px;
  color: #CFDACE;
}
.post-slide .read-more{
  padding: 7px 20px;
  float: right;
  font-size: 12px;
  background: #2196F3;
  color: #ffffff;
  box-shadow: 0px 10px 20px -10px #1376c5;
  border-radius: 25px;
  text-transform: uppercase;
}
.post-slide .read-more:hover{
  background: #3498db;
  text-decoration:none;
  color:#fff;
}
.owl-controls .owl-buttons{
  text-align:center;
  margin-top:20px;
}
.owl-controls .owl-buttons .owl-prev{
  background: #fff;
  position: absolute;
  top:-13%;
  left:15px;
  padding: 0 18px 0 15px;
  border-radius: 50px;
  box-shadow: 3px 14px 25px -10px #92b4d0;
  transition: background 0.5s ease 0s;
}
.owl-controls .owl-buttons .owl-next{
  background: #fff;
  position: absolute;
  top:-13%;
  right: 15px;
  padding: 0 15px 0 18px;
  border-radius: 50px;
  box-shadow: -3px 14px 25px -10px #92b4d0;
  transition: background 0.5s ease 0s;
}
.owl-controls .owl-buttons .owl-prev:after,
.owl-controls .owl-buttons .owl-next:after{
  content:"\f104";
  font-family: FontAwesome;
  color: #333;
  font-size:30px;
}
.owl-controls .owl-buttons .owl-next:after{
  content:"\f105";
}
@media only screen and (max-width:1280px) {
  .post-slide .post-content{
    padding: 0px 15px 25px 15px;
  }
}


.chat-btn {
  position: fixed;
  right: 14px;
  bottom: 55px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 22px;
  z-index: 999;
}

.chat-btn > .close {
  display: none
}

#feedback-hidden-check:checked~.chat-btn > .close {
  display: block;
  pointer-events: auto;
}

#feedback-hidden-check:checked~.chat-btn .comment {
  display: none
}

.chat-btn > .close {
  font-size: 22px;
}

#feedback-box-wrapper {
  position: fixed;
  right: 20px;
  bottom: 100px;
  width: 300px;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.4s;
  background-color: #2196f3;
  z-index: 999;
}

#feedback-hidden-check:checked~#feedback-box-wrapper {
  opacity: 1
}

#feedback-header {
  padding: 13px;
  color: white;
  background-color: #212529;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 10px;
}

.chat-form {
  padding: 15px
}

.chat-form > input, .chat-form > textarea, .chat-form > button {
  margin-bottom: 15px;
}

.chat-form textarea {
  resize: none
}

#feedback-hidden-check {
  display: none !important
}